<template>
  <div id="exhibition_list">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- list -->
        <div class="col-lg-8">
          <!-- <h2 class="list_title" v-html="translate.global.exhibition_item_list_title[language]"></h2>
          <ExhibitionList v-for="(photoId, i) in photosInExhibition" :photoId="photoId" :key="'photolist_item_' + i" /> -->
          <h2 class="list_title"
            v-html="translate.global.all_photos[language]"></h2>
          <ExhibitionList v-if="typeof photos !== 'undefined' &&
            photo['ghwk:exhibitstatus'][0]['@value'] === 'im Bestand' ||
            photo['ghwk:exhibitstatus'][0]['@value'] === 'in Ausstellung'"
            v-for="(photo, i) in photos"
            :photoId="photo['o:id']"
            :key="'photolist_item_instore_' + i" />
        </div>
        <!-- side -->
        <div class="col-lg-4 exhibition_side_col">
          <h1>{{ translate.global.exhibition_item_list_title[language] }}</h1>
          <hr>
          <dl>
            <dt v-html="translate.metadata_label['thematische Kategorie'][language]"></dt>
            <dd><SubjectcategoryNavigation /></dd>
            <dt v-html="translate.metadata_label['Personen'][language]"></dt>
            <dd><PersonsNavigation /></dd>
            <dt v-html="translate.metadata_label['Orte'][language]"></dt>
            <dd><LocationsNavigation /></dd>
          </dl>
          <hr>
          <GolbalListLinks />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import ExhibitionList from '@/components/partials/ExhibitionList.vue'
import SubjectcategoryNavigation from '@/components/partials/SubjectcategoryNavigation.vue'
import PersonsNavigation from '@/components/partials/PersonsNavigation.vue'
import LocationsNavigation from '@/components/partials/LocationsNavigation.vue'
import GolbalListLinks from '@/components/partials/GolbalListLinks.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Exhibition',
  components: {
    'site-header': header,
    ExhibitionList,
    SubjectcategoryNavigation,
    PersonsNavigation,
    LocationsNavigation,
    GolbalListLinks
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photosInExhibition: 'items/photosInExhibition',
      media: 'media/media'
    }),
    photoTitle: function () {
      let title = '';
      if (typeof this.photo !== 'undefined' && this.photo !== null && this.photo['ghwk:caption'].length > 0) {
        title = this.photo['ghwk:caption'].find(caption => caption['@language'] === this.language)
        if (title !== '') { title = title['@value'] }
      }
      return title
    },
    ghwkDatedisplay: function () {
      let dates = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.photo['ghwk:datedisplay'] !== 'undefined' &&
        this.photo['ghwk:datedisplay'].length > 0)
      {
        dates = this.photo['ghwk:datedisplay'].find(date => date['@language'] === this.language)
        if (dates !== '') { dates = dates['@value'] }
      }
      return dates
    },
    latitudeLongitude: function () {
      return (typeof this.photo !== 'undefined' && this.photo !== null &&
      typeof this.photo['ghwk:latitude'] !== 'undefined' &&
      this.photo['ghwk:latitude'].length > 0 &&
      typeof this.photo['ghwk:longitude'] !== 'undefined' &&
      this.photo['ghwk:longitude'].length > 0)?
        {
          latitude: this.photo['ghwk:latitude'][0]['@value'],
          longitude: this.photo['ghwk:longitude'][0]['@value']
        } : false
    },
    locationTitle: function() {
      if(typeof this.photo !== 'undefined' && this.photo !== null &&
      typeof this.photo['ghwk:location'] !== 'undefined' &&
      this.photo['ghwk:location'].length > 0) {
        return this.photo['ghwk:location'][0]['display_title']
      } else {
        return ''
      }
    }
  }
}
</script>